<template>
    <transition v-if="false && dialog" name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
                        <slot name="header">
                            <h3>Уважаемые пользователи, вы можете скачать мобильную версию Системы для просмотра статуса разработки проекта</h3>
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                            <div class="useful-res">
                                <div class="useful-res-banners">
                                    <center style="margin-top:20px">
                                        <div class="qr">
                                            <div class="code">
                                                <img class="useful-res-banner-image" src="@/assets/images/onlink_to_ios_android.png" width="150">
                                            </div>
                                            <div>
                                                <span>* Наведите камеру телефона на QR-код,</span>
                                                <br>
                                                <span>чтобы скачать приложение</span>
                                            </div>
                                        </div>
                                        <a class="useful-res-banner" href="https://play.google.com/store/apps/details?id=ru.scid.monitoring&hl=ru&gl=US" target="_blank" title="Скачать с Google Play" rel="noopener noreferrer">
                                            <img class="useful-res-banner-image" src="@/assets/images/button_download_android.png" width="340">
                                        </a>
                                        <!-- <a class="useful-res-banner" href='https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%BD%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%BD%D0%B3-%D0%BA%D1%80/id1585366654' target="_blank" title="Скачать c App Store" rel="noopener noreferrer">
                                            <img class="useful-res-banner-image" src="@/assets/images/button_download_ios.png" width="340">
                                        </a> -->
                                    </center>
                                </div>
                            </div>
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="b-button b-button--success" @click="dialog = false">Закрыть</button>
                            <button class="b-button b-button--cancel ml-2" @click="saveLS">Больше не показывать</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>

  <!-- <v-dialog
	persistent
    v-model="dialog"
	max-width="910px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Уважаемые пользователи, теперь вы можете установить мобильную версию Рубрикатора!</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
		  <div class="useful-res">
			  <div class="useful-res-banners">
				  <center style="margin-top:20px">
					  <div class="qr">
						  <div class="code">
							  <img class="useful-res-banner-image" src="@/assets/images/onlink_to_ios_android.png" width="150">
						  </div>
						  <div>
							  <span>* Наведите камеру телефона на QR-код,</span>
							  <br>
							  <span>чтобы скачать приложение</span>
						  </div>
					  </div>
					  <a class="useful-res-banner" href="https://play.google.com/store/apps/details?id=ru.scid.crminzdr" target="_blank" title="Скачать с Google Play" rel="noopener noreferrer">
						<img class="useful-res-banner-image" src="@/assets/images/button_download_android.png" width="400">
					  </a>
					  <a class="useful-res-banner" href='javascript:void(0);' title="Скачать c App Store">
						<img class="useful-res-banner-image" src="@/assets/images/button_download_ios.png" width="400">
					  </a>
				  </center>
			  </div>
		  </div>
	  </v-card-text>
	  	<v-card-actions style="display:inherit;text-align:center">
			<v-btn color="info darken-1" text @click="dialog = false">Закрыть</v-btn>
			<v-btn color="error darken-1" text @click="saveLS">Больше не показывать</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog> -->
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: () => false },
  },
  data() {
    return {

    }
  },
  methods: {
	  saveLS() {
		localStorage.setItem('checkInfoMobile', true)
		this.dialog = false
	  }
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 810px;
    margin: 0px auto;
    padding: 0px 0px 30px 0px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    font-size: 1.25rem;
    line-height: 1.5;
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
.modal-header {
    background: #04092a;
    padding: 15px;
    align-items: center;
    display: flex;
    position: relative;
    border-radius: 7px 7px 0 0;
    text-align: center;
}
.modal-footer {
	text-align: center;
}
.modal-body {
  margin: 20px 0;
}

.modal-enter-active {
  transition: all 0.9s ease-out;
}

.modal-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.modal-enter-from,
.modal-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.qr {
    text-align: center;
    margin-bottom: 10px;
}

.qr .code {
    padding: 16px;
    display: inline-block;
    border-radius: 8px;
    border-top-color: rgb(111, 224, 119);
    border-top-style: solid;
    border-top-width: 2px;
    border-right-color: rgb(111, 224, 119);
    border-right-style: solid;
    border-right-width: 2px;
    border-bottom-color: rgb(111, 224, 119);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-left-color: rgb(111, 224, 119);
    border-left-style: solid;
    border-left-width: 2px;
    position: relative;
}

.qr .code img {
    position: relative;
    z-index: 1;
    width: 180px;
    height: 180px;
}

.qr .code:after {
    top: -3px;
    bottom: -3px;
    left: 20px;
    right: 20px;
}

.qr .code:before {
    top: 20px;
    bottom: 20px;
    left: -3px;
    right: -3px;
}

.qr .code:after, .qr .code:before {
    content: "";
    position: absolute;
    background: #fff;
}

.useful-res-banner-image {
  max-width: 100%
}

</style>
