import orderBy from "lodash.orderby";
export default {
  namespaced: true,
  state: {
    list_companies: [],
    list_companies_mz: [],
    notification: [],
  },
  getters: {
    getListCompanies: (state) => state.list_companies,
    getListCompaniesMZ: (state) => state.list_companies_mz,
    gettersNotification: (state) => state.notification.filter((f) => f.view),
  },
  mutations: {
    set(state, obj) {
      if (obj.name == "list_companies_mz")
        obj.data.forEach((el) => {
          el.companyName = el.company ? el.company.name : "";
        });
      if (obj.name == "list_companies")
        obj.data.forEach((el) => {
          el.companyName = el.company ? el.company.name : "";
          el.roleName = el.role ? el.role.name : "";
        });
      if (obj.name == "notification")
        obj.data.forEach((el) => {
          el.view = true;
        });

      state[obj.name] = obj.data;
    },
    sortMutationMZ(state, obj) {
      state.list_companies_mz = orderBy(
        state.list_companies_mz,
        obj.column,
        obj.sort
      );
    },
    sortMutation(state, obj) {
      state.list_companies = orderBy(
        state.list_companies,
        obj.column,
        obj.sort
      );
    },
    allNotificationTrue(state) {
      state.notification.forEach((el) => (el.view = false));
    },
  },
  actions: {
    getCompanies({ rootState, commit }, params) {
      this.api
        .post(`${rootState.api_url}api/Companies/filters`, {
          name: params.MPNKO ? params.MPNKO : undefined,
          inn: params.inn ? params.inn : undefined,
          email: params.email ? params.email : undefined,
        })
        .then((res) => {
          if (res.status == 200)
            commit("set", { data: res.data, name: "list_companies" });
        });
    },
    getCompaniesMZ({ rootState, commit }, params) {
      return new Promise((resolve, reject) => {
        this.api
          .post(`${rootState.api_url}api/Companies/filters`, {
            roleId: 1,
            name: params.MPNKO ? params.MPNKO : undefined,
            inn: params.inn ? params.inn : undefined,
            email: params.email ? params.email : undefined,
          })
          .then(
            (res) => {
              if (res.status == 200) {
                commit("set", { data: res.data, name: "list_companies_mz" });
              }
              resolve(res);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    getNotification({ rootState, commit }) {
      this.api
        .get(`${rootState.api_url}api/Notifications?IsRead=${false}`)
        .then((res) => {
          if (res.status == 200)
            commit("set", {
              data: res.data.sort((a, b) =>
                a.createdOn < b.createdOn ? 1 : -1
              ),
              name: "notification",
            });
        });
    },
  },
};
