export default {
	namespaced: true,
	state: {
		analytics: {},
		application: [],
		notification: [],
		decision: [],
		expertReview: [],
		total: {},
	},
	getters: {
		gettersAnalitics: state => state.analytics,
		gettersApplication: state => state.application,
		gettersNotification: state => state.notification,
		gettersDecision: state => state.decision,
		gettersExpertReview: state => state.expertReview,
		gettersTotal: state => state.total,
		gettersMax: state => _.max(state.application[0].data),
	},
	mutations: {
		set(state, obj) {
			state.analytics[obj.type] = obj.data
		},
		mutationAplication(state, obj) {
			state.application = [{
				data: [
					obj.data.developProjectsCount.sentToCEKKMPStatusCount,
					obj.data.developProjectsCount.inProgress,
					obj.data.developProjectsCount.sentForRevisionStatusCount
				]
			}]
		},
		mutationNotification(state, obj) {
			state.notification = [{
				data: [
					obj.data.developProjectsCount.realizedProjectsCount,
					obj.data.developProjectsCount.inWorkNotStatementActualProjectsCount,
					obj.data.developProjectsCount.notRealizedNotStatementNotActualProjectsCount,
				]
			}]
		},
		mutationDecision(state, obj) {
			state.decision = [{
				data: [
					obj.data.approvedProjectsCount,
					obj.data.rejectedProjectsCount,
					obj.data.revisionProjectsCount,
				]
			}]
		},
		mutationExpertReview(state, obj) {
			state.expertReview = [{
				data: [
					obj.data.successWithoutСommentsProjectsCount,
					obj.data.successWithСommentsProjectsCount,
				]
			}]
		},
		mutationTotal(state, obj) {
			state.total = obj.data
		},
		mutationNotificationSerias(state, bool) {
			if (bool) {
				state.notification = [{
					data: [
						state.analytics.notification.developProjectsCount.realizedProjectsCount,
						state.analytics.notification.developProjectsCount.inWorkNotStatementActualProjectsCount,
						state.analytics.notification.developProjectsCount.notRealizedNotStatementNotActualProjectsCount,
					]
				}]
			} else {
				state.notification = [{
					data: [
						state.analytics.notification.revisionProjectsCount.realizedProjectsCount,
						state.analytics.notification.revisionProjectsCount.inWorkNotStatementActualProjectsCount,
						state.analytics.notification.revisionProjectsCount.notRealizedNotStatementNotActualProjectsCount,
					]
				}]
			}
		},
		mutationApplicationSerias(state, value) {
			state.application = [{
				data: [
					state.analytics.application[value].sentToCEKKMPStatusCount,
					state.analytics.application[value].inProgress,
					state.analytics.application[value].sentForRevisionStatusCount
				]
			}]
		}
	},
	actions: {
		projectNotificationStageInfo({rootState, commit}, data) {
			return this.api.post(`${rootState.api_url_analitics}GetProjectNotificationStageInfo`, data)
			.then(res => {
				if(res.status == 200) {
					commit('mutationNotification', {data: res.data})
					commit('set', {data: res.data, type: 'notification'})
				}
				return res
			})
		},
		projectApplicationStageInfo({rootState, commit}, data) {
			return this.api.post(`${rootState.api_url_analitics}GetProjectApplicationStageInfo`, data)
			.then(res => {
				if(res.status == 200) {
					commit('mutationAplication', {data: res.data})
					commit('set', {data: res.data, type: 'application'})
				}
				return res
			})
		},
		projectExpertReviewInfo({rootState, commit}, data) {
			return this.api.post(`${rootState.api_url_analitics}GetProjectExpertReviewInfo`, data)
			.then(res => {
				if(res.status == 200) {
					commit('mutationExpertReview', {data: res.data})
					commit('set', {data: res.data, type: 'expertReviewInfo'})
				}
				return res
			})
		},
		projectNPSDecisionInfo({rootState, commit}, data) {
			return this.api.post(`${rootState.api_url_analitics}GetProjectNPSDecisionInfo`, data)
			.then(res => {
				if(res.status == 200) {
					commit('mutationDecision', {data: res.data})
					commit('set', {data: res.data, type: 'decisionInfo'})
				}
				return res
			})
		},
		projectTotalCount({rootState, commit}, data) {
			return this.api.post(`${rootState.api_url_analitics}GetProjectTotalCountInfo`, data)
			.then(res => {
				if(res.status == 200) {
					commit('mutationTotal', {data: res.data})
					commit('set', {data: res.data, type: 'totalCountInfo'})
				}
				return res
			})
		},
	},
};
