<template lang='pug'>
    footer(:class="$style.footer")
        div(:class="$style.container")
            div(:class="$style.wrapper")
                p(:class="$style.copyright") © {{ new Date().getFullYear() }}
                    span(:class="$style.title")  Министерство здравоохранения Российской Федерации. 
                    | Все права защищены
                    span(:class="$style.title" style="float:right")  Служба технической поддержки: support-std@minzdrav.gov.ru
</template>
<script>
    export default {
        name: 'AppFooter'
    }
</script>

<style lang='less' module>
    @import "./style/mobile";

    @media (min-width: 768px) {
        @import "./style/tablet";
    }

    @media (min-width: 1366px) {
        @import "./style/desktop";
    }
</style>
