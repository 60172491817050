import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Notifications from "vue-notification";
import VueApexCharts from "vue-apexcharts";
import VueLodash from "vue-lodash";
import lodash from "lodash";

Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.use(require("vue-moment"));
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(Notifications);
Vue.component("v-select", vSelect);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.directive("phone", {
  bind(el) {
    el.oninput = function(e) {
      if (!e.isTrusted) {
        return;
      }

      const x = this.value
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);

      if (!x[2] && x[1] !== "") {
        this.value = x[1] === "8" ? x[1] : "8" + x[1];
      } else {
        this.value = !x[3]
          ? x[1] + x[2]
          : x[1] + "(" + x[2] + ") " + x[3] + (x[4] ? "-" + x[4] : "");
      }
    };
  },
});

Vue.prototype.api = store.api = axios.create({
  withCredentials: true,
});
store.api.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {

    if (error.response.status === 401) {
      store.api.post(`${store.state.api_url}api/account/logout`);
      router.replace("/");
    }
    return Promise.reject(error);
  }
);
